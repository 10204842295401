import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enTransaltion from './lang/en.json'
import esTransaltion from './lang/es.json'
import brTransaltion from './lang/br.json'

const resources = {
    en: {
        translation: enTransaltion,
    },
    es: {
        translation: esTransaltion,
    },
    br: {
        translation: brTransaltion,
    }
}

i18n.use(initReactI18next).init({
    resources,
    lng: 'es',
    keySeparator: false,
    interpolation: {
        escapeValue: false,
    }
})

export default i18n