import styled from "@emotion/styled"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft, faQuoteRight, faStar } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { Slide } from 'react-reveal'

function TestimonialsComponent() {

    const { t } = useTranslation()

  return (
    <Container id="testimonials">
        <TestimonialWrapper>
            <h2>{t('testimonials.title')}</h2>
            <TestimonialSliderWrapper>
                <Slide left cascade>
                    <TextimonialBox>
                        <StarsWrapper>
                            <ul>
                                <li>
                                    <FontAwesomeIcon icon={faStar} />
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faStar} />
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faStar} />
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faStar} />
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faStar} />
                                </li>
                            </ul>
                        </StarsWrapper>
                        <p><FontAwesomeIcon icon={faQuoteLeft} /> {t('testimonials.text.description.1')} <FontAwesomeIcon icon={faQuoteRight} /></p>
                        <span>Luisana Q</span>
                    </TextimonialBox>
                    <TextimonialBox>
                        <StarsWrapper>
                            <ul>
                                <li>
                                    <FontAwesomeIcon icon={faStar} />
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faStar} />
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faStar} />
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faStar} />
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faStar} />
                                </li>
                            </ul>
                        </StarsWrapper>
                        <p><FontAwesomeIcon icon={faQuoteLeft} /> {t('testimonials.text.description.2')} <FontAwesomeIcon icon={faQuoteRight} /></p>
                        <span>Daniel E</span>
                    </TextimonialBox>
                    <TextimonialBox>
                        <StarsWrapper>
                            <ul>
                                <li>
                                    <FontAwesomeIcon icon={faStar} />
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faStar} />
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faStar} />
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faStar} />
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faStar} />
                                </li>
                            </ul>
                        </StarsWrapper>
                        <p><FontAwesomeIcon icon={faQuoteLeft} /> {t('testimonials.text.description.3')} <FontAwesomeIcon icon={faQuoteRight} /></p>
                        <span>Mauro V</span>
                    </TextimonialBox>
                </Slide>
            </TestimonialSliderWrapper>
        </TestimonialWrapper>
    </Container>
  )
}

export default TestimonialsComponent

const Container = styled.div`
    min-height: 200px;
    width: 100%;
    background: #f6f6f6;
    padding: 50px 50px;
    box-sizing: border-box;
`
const TestimonialWrapper = styled.div`
    min-height: 20px;
    width: 1100px;
    margin: auto;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2 {
        font-size: 2em;
        margin: 0;
        padding: 0;
        color: #1C0C5B;
    }
    @media screen and (max-width: 1171px){ 
        width: 100%;
    }

`
const TestimonialSliderWrapper = styled.div`
    min-height: 100px;
    width: 100%;
    margin-top: 60px;
    border-radius: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 40px;
    @media screen and (max-width: 1171px){
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
    }
`
const TextimonialBox = styled.div`
    min-height: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    transform: scale(1);
    transition: transform ease .3s;
    p {
        margin: 0;
        padding: 0;
        font-size: 1.1rem;
        font-weight: 400;
        letter-spacing: -.3px;
        color: #2b2b2a;
    }

    span {
        font-size: 1.2rem;
        font-weight: 700;
        margin-top: 40px;
        color: #1C0C5B;
    }

    &::after{
        content: '';
        height: 3px;
        width: 100%;
        background: #1C0C5B;
        position: absolute;
        top: -15px;
        opacity: 0;
        transition: all ease .3s;
    }

    &:hover {
        transform: scale(1.1);
    }
    
    &:hover::after{
        opacity: 1;
    }
`

const StarsWrapper = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0%;
        display: inline-block;
    }
    li{
        display: inline-block;
        margin-left: 10px;
        color: #f1c232;
        &:nth-of-type(1){
            margin-left: 0;
        }
    }
`