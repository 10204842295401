import styled from "@emotion/styled"

function CarrouselComponent() {
  return (
    <Container>
      
    </Container>
  )
}

export default CarrouselComponent

const Container = styled('div')(({ theme }) => ({
  minHeight: '500px',
  width: '100%',
  backgroundColor: theme.palette.background.paper,
}))