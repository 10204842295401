import { useState } from 'react'
import {
    Tabs,
    Tab,
    Box,
    Typography
} from '@mui/material'
import PropTypes from 'prop-types'
import styled from "@emotion/styled"
import { useTranslation } from 'react-i18next'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  }

function RegionsComponent() {

    const { t } = useTranslation()

    const [value, setValue] = useState(0)

    const handleChange = (event, newValue) => {
      setValue(newValue)
    }
  return (
    <Container id="regions">
        <RegionsContent>
            <h2>{t('regions.title')}</h2>
            <RegionsWrapper>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                              <Tab label={t('region.type.1')} sx={{ color: '#1C0C5B', fontSize: '1rem' }}/>
                              <Tab label={t('region.type.2')} sx={{ color: '#1C0C5B', fontSize: '1rem' }}/>
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <MapWrapperMultiple>
                                <img src="static/img/mapas_BA-01.svg" alt="" loading='lazy'/>
                                <img src="static/img/mapas_BA-04.svg" alt="" loading='lazy'/>
                            </MapWrapperMultiple>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <MapWrapper>
                                <img src="static/img/mapas_sp-01.svg" alt="" loading='lazy'/>
                            </MapWrapper>
                        </TabPanel>
                    </Box>
            </RegionsWrapper>
        </RegionsContent>
    </Container>
  )
}

export default RegionsComponent

const Container = styled('div')(({ theme }) => ({
    minHeight: '100px',
    width: '100%',
    position: 'relative',
    backgroundColor: theme.palette.default[100],
    boxSizing: 'border-box',
    display: 'flex',
    padding: '100px 50px',
    paddingBottom: '50px',
}))

const RegionsContent = styled.div`
    min-height: 100px;
    width: 1100px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
        font-size: 2em;
        margin: 0;
        padding: 0;
        color: #1C0C5B;
    }
    @media screen and (max-width: 1142px){
      width: 100%;
    }
`

const RegionsWrapper = styled.div`
    min-height: 100px;
    width: 100%;
    margin-top: 50px;
`

const MapWrapper = styled.div`
    min-height: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
        height: auto;
        width: 700px;
        object-fit: cover;
        @media screen and (max-width: 1142px){
          width: 500px;
        }
    }
`

const MapWrapperMultiple = styled.div`
    min-height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        height: auto;
        width: 700px;
        object-fit: cover;
        @media screen and (max-width: 1292px){
          width: 500px;
        }
    }

    @media screen and (max-width: 996px){
      flex-direction: column;
    }
    
`