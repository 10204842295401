import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

function ServiceComponent() {

    const { t } = useTranslation()

  return (
    <Container id="services">
        <ServiceWrapper>
            <h2>{t('service.title')}</h2>
            <ServiceItems>
                <ServiceItemBox>
                    <img src="static/img/icon-ecommerce-06.png" alt=""/>
                    <h3>{t('service.title.item.title.1')}</h3>
                </ServiceItemBox>
                <ServiceItemBox>
                    <img src="static/img/icon-marketplace-06.png" alt=""/>
                    <h3>{t('service.title.item.title.2')}</h3>
                </ServiceItemBox>
                <ServiceItemBox>
                    <img src="static/img/icon-retails-06.png" alt=""/>
                    <h3>{t('service.title.item.title.3')}</h3>
                </ServiceItemBox>
                <ServiceItemBox>
                    <img src="static/img/icon-correos-06.png" alt=""/>
                    <h3>{t('service.title.item.title.4')}</h3>
                </ServiceItemBox>
            </ServiceItems>
        </ServiceWrapper>
    </Container>
  )
}

export default ServiceComponent

const Container = styled('div')(({ theme }) => ({
    minHeight: '100px',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: '50px 50px',
    boxSizing: 'border-box'
}))

const ServiceWrapper = styled.div`
    min-height: 100px;
    width: 1100px;
    margin: auto; 
    margin-bottom: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h2 {
        color: #fff;
        font-size: 2em;
        margin: 0;
        padding: 0;
    }
    @media screen and (max-width: 1276px){
        width: 100%;
    }
`

const ServiceItems = styled.div`
    min-height: 100px;
    width: 100%;
    margin-top: 50px;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr 1fr;
    }
`

const ServiceItemBox = styled.div`
    min-height: 100px;
    width: 100%;
    background: #fff;
    border-radius: 10px;
    padding: 20px 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    img {
        height: 100px;
        width: 100px;
        object-fit: cover;
    }
`