import { createTheme } from '@mui/material'

export const theme = createTheme({
    breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 1000,
          lg: 1200,
          xl: 1920
        }
    },
    palette: {
        default: {
            100: '#f6f6f6',
            200: '#6F39C2',
            300: '',
            400: '',
            500: '',
            600: '',
            700: '',
            800: '#1A1A40'
        },
        primary: {
            light: '#7a00f9',
            main: '#7a00f9'
        },
        secondary: {
            light: '#505056',
            main: '#505056'
        },
        background: {
            default: '#1C0C5B',
            paper: '#3D2C8D'
        }
    }
})