import styled from "@emotion/styled"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import ImgIcon from '../../assets/img/shipping.png'
import { useTranslation } from 'react-i18next'
import { Fade } from 'react-reveal'

function PresentationComponent() {

  const { t, i18n } = useTranslation()

  return (
    <Container id="top">
      <ContentWrapper>
        <Fade left cascade>
          <InfoWrapper>
            <span>{t('presentation.welcome.title')}</span>
            <h1>{t('presentation.title')}</h1>
            {i18n.language === 'es' && (
              <a href="https://api.whatsapp.com/send?phone=5491168299658&text=Hola, quisiera pedir una cotización por el servicio." target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faWhatsapp} />{t('presentation.button.text')}</a>
            )}
            {i18n.language === 'en' && (
              <a href="https://api.whatsapp.com/send?phone=5491168299658&text=Hola, quisiera pedir una cotización por el servicio." target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faWhatsapp} />{t('presentation.button.text')}</a>
            )}
            {i18n.language === 'br' && (
              <a href="https://api.whatsapp.com/send?phone=5511952967358&text=Olá, gostaria de mais informações sobre o serviço." target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faWhatsapp} />{t('presentation.button.text')}</a>
            )}
          </InfoWrapper>
        </Fade>
        <Fade right cascade>
          <ImgWrapper>
            <img src={ImgIcon} alt="" />
          </ImgWrapper>
        </Fade>
      </ContentWrapper>
    </Container>
  )
}

export default PresentationComponent

const Container = styled('div')(({ theme }) => ({
  minHeight: '200px',
  width: '100%',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
  boxSizing: 'border-box',
  display: 'flex',
  padding: '100px 50px',
  clipPath: `ellipse(99% 100% at 50% 0%)`,
}))

const ContentWrapper = styled.div`
  min-height: 100px;
  width: 1100px;
  margin: auto;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  @media screen and (max-width: 911px){
    align-items: center;
    justify-content: center;
  }
`
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  span {
    color: #fff;
    font-size: 1.4rem;
    letter-spacing: -.3px;
  }

  h1 {
    color: #fff;
    font-size: 2.3em;
    margin: 0;
    line-height: 42px;
    text-transform: uppercase;
    @media screen and (max-width: 970px){
      font-size: 2em;
    }
    @media screen and (max-width: 911px){
      text-align: center;
    }
  }

  a {
    width: 300px;
    text-decoration: none;
    padding: 10px 20px;
    box-sizing: border-box;
    background: #fff;
    display: inline-block;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    font-size: 1.1rem;
    color: #1C0C5B;
    transform: scale(1);
    transition: transform ease .3s;
    &:hover{
      cursor: pointer;
      transform: scale(1.1);
    }
  }
  @media screen and (max-width: 911px){
    justify-content: center;
    align-items: center;
  }
`

const ImgWrapper = styled.div`
  img {
    height: auto;
    width: 500px;
    object-fit: cover;
    @media screen and (max-width: 1200px){
      width: 400px;
    }
  }
  @media screen and (max-width: 911px){
      display: none;
  }
`