import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { HomePage, BlankPage } from './pages/Routes'
import './general.sass'

function App() {
  return (
    <div className="App">
      <Router>
        <RoutePages />
      </Router>
    </div>
  );
}


const RoutePages = () => (
  <Routes>
    <Route exact path="/" element={<HomePage />}/>
    <Route path="*" element={<BlankPage />}/>
  </Routes>
)


export default App
