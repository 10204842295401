import { action, makeObservable, observable } from 'mobx'

export class LangStore {
    constructor(){
        this.lang = 'es'

        makeObservable(this, {
            // Obsevable
            lang: observable,
            // Actions
            setLanguage: action,
        })
    }

    setLanguage(newLang) {
        this.lang = newLang
    }
}