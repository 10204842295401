import { useState } from 'react'
import axios from 'axios'
import styled from "@emotion/styled"
import {
  TextField,
  Box
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import Recaptcha from 'react-google-recaptcha'


function ContactComponent() {

  const { t } = useTranslation()

  const [name, setName] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [messageSuccess, setMessageSuccess] = useState(false)
  const [messageError, setMessageError] = useState(false)
  const [messageServerError, setMessageServerError] = useState(false)
  const [btnDisable, setBtnDisable] = useState(true)


  const handleChangeName = (e) => {
    setName(e.target.value)
    refreshMessageStatus()
  }

  const handleChangeLastName = (e) => {
    setLastname(e.target.value)
    refreshMessageStatus()
  }

  const handleChangeEmail = (e) => {
    setEmail(e.target.value)
    refreshMessageStatus()
  }

  const handleChangeMessage = (e) => {
    setMessage(e.target.value)
    refreshMessageStatus()
  }

  const refreshMessageStatus = () => {
    setMessageSuccess(false)
    setMessageError(false)
    setMessageServerError(false)
  }

  const handleChangeCaptcha = (val) => {
    debugger
    console.log(val)
    setBtnDisable(false)
  }


  const handleClick = async () => {
    const data = {
      lang: 'es',
      name,
      lastname,
      email,
      message
    }

    try {
      const response = await axios.post('https://ef-artemis.herokuapp.com/api/v1/mailing', data)

        if(response.data.code === 200) {
          setMessageSuccess(true)
          setTimeout(() => {
            setName('')
            setLastname('')
            setEmail('')
            setMessage('')
            setMessageSuccess(false)
          }, 8000)
        } else {
          setMessageError(true)
        }

    } catch (error) {
      // Redirect to corrsponding page
      setMessageServerError(true)
    }
  }

  return (
    <Container id="contact">
      <ContactWrapper>
        <h2>{t('contact.title')}</h2>
        <ContactInfo>
          <ContactItems>
            <ContactBox>
              <ContactBoxIcon>
                <FontAwesomeIcon icon={faPhone}/>
              </ContactBoxIcon>
              <ul>
                <li>
                  +54 9 11 6829-9658
                </li>
                <li>
                  +55 11 95296-7358
                </li>
              </ul>
            </ContactBox>
            <ContactBox>
              <ContactBoxIcon>
                <FontAwesomeIcon icon={faEnvelope}/>
              </ContactBoxIcon>
              <ul>
                <li>
                  contactoexpressflex@gmail.com
                </li>
              </ul>
            </ContactBox>
            <ContactBox>
              <ContactBoxIcon>
                <FontAwesomeIcon icon={faLocationDot}/>
              </ContactBoxIcon>
              <ul>
                <li>
                  Maza 763, CABA Buenos Aires
                </li>
                <li>
                  San Pablo
                </li>
              </ul>
            </ContactBox>
          </ContactItems>
          <FormWrapper>
            <Box sx={{ width: '100%' }}>
              <TextField label={t('contact.label.name')} size='small' fullWidth onChange={handleChangeName} value={name} required/>
            </Box>
            <Box sx={{ width: '100%', mt: '20px' }}>
              <TextField label={t('contact.label.lastname')} size='small' fullWidth value={lastname} onChange={handleChangeLastName} required/>
            </Box>
            <Box sx={{ width: '100%', mt: '20px' }}>
              <TextField label={t('contact.label.email')} size='small' type='email' fullWidth value={email} onChange={handleChangeEmail} required/>
            </Box>
            <Box sx={{ width: '100%', mt: '20px' }}>
              <TextField label={t('contact.label.message')} multiline maxRows={20} size='small' fullWidth value={message} onChange={handleChangeMessage} required/>
            </Box>
            <Box sx={{ width: '100%', mt: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Recaptcha sitekey="6LeDAyMgAAAAAOIDBa5Bbl2N1xboJplx67ff5E3d" onChange={handleChangeCaptcha}/>
            </Box>
            {!btnDisable ? (
              <Box sx={{ width: '100%', mt: '20px' }}>
                <ButtonItem onClick={handleClick}>{t('contact.button.text')}</ButtonItem>
              </Box>
            ) : (
              <Box sx={{ width: '100%', mt: '20px' }}>
                <ButtonItemDisable onClick={handleClick}>{t('contact.button.text')}</ButtonItemDisable>
              </Box>
            )}
            {messageSuccess && (
              <MessageBoxSuccess>
                {t('contact.message.success')}
              </MessageBoxSuccess>
            )}
            {messageError && (
              <MessageBoxError>
                {t('contact.message.error')}
              </MessageBoxError>
            )}
            {messageServerError && (
              <MessageBoxServerError>
                {t('contact.message.server.error')}
              </MessageBoxServerError>
            )}
          </FormWrapper>
        </ContactInfo>
      </ContactWrapper>
    </Container>
  )
}

export default ContactComponent

const Container = styled('div')(({ theme }) => ({
    minHeight: '100px',
    width: '100%',
    position: 'relative',
    backgroundColor: theme.palette.default[100],
    boxSizing: 'border-box',
    display: 'flex',
    padding: '100px 50px',
    paddingTop: '50px',
}))

const ContactWrapper = styled.div`
  min-height: 20px;
  width: 1100px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
      font-size: 2em;
      margin: 0;
      padding: 0;
      color: #1C0C5B;
    }
`
const FormWrapper = styled.div`
  min-height: 100px;
  width: 500px;
  margin: auto;
  background: #efefef;
  padding: 30px 30px;
  box-sizing: border-box;
  border-radius: 20px;
  margin-top: 50px;
`
const ContactInfo = styled.div`
  min-height: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const ContactItems = styled.div`
  min-height: 100px;
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  @media screen and (max-width: 1046px){
    flex-direction: column;
    justify-content: center;
  }
`
const ContactBox = styled.div`
  min-height: 180px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 3px 10px 23px -10px rgba(199,199,199,0.6);
  padding: 20px 20px;
  box-sizing: border-box;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    margin-top: 20px;
  }

  li {
    font-weight: 300;
    font-size: 1.2rem; 
    margin-top: 10px;
    &:nth-of-type(1){
      margin-top: 0;
    }
  }

`
const ContactBoxIcon = styled.div`
  color: #1C0C5B;
  font-size: 3em;
`
const ButtonItem = styled.div`
  padding: 20px 20px;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  background: rgb(122,0,249);
  background: -moz-linear-gradient(90deg, rgba(122,0,249,1) 0%, rgba(221,78,226,1) 87%);
  background: -webkit-linear-gradient(90deg, rgba(122,0,249,1) 0%, rgba(221,78,226,1) 87%);
  background: linear-gradient(90deg, rgba(122,0,249,1) 0%, rgba(221,78,226,1) 87%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#7a00f9",endColorstr="#dd4ee2",GradientType=1);
  color: #fff;
  font-weight: 500;
  font-size: 1.1rem;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
  }
`
const ButtonItemDisable = styled(ButtonItem)`
  background: #ddd;
`

const MessageBoxSuccess = styled.div`
  min-height: 20px;
  width: 100%;
  margin-top: 20px;
  padding: 5px 10px;
  box-sizing: border-box;
  border: 1px solid #badbcc;
  color: #0f5132;
  background: #d1e7dd;
  border-radius: 5px;
  line-height: 22px;
  letter-spacing: -.3px;
  font-weight: 400;
`

const MessageBoxError = styled(MessageBoxSuccess)`
  color: #842029;
  background: #f8d7da;
  border: 1px solid #f5c2c7;
`

const MessageBoxServerError = styled(MessageBoxSuccess)`
  color: #084298;
  background: #cfe2ff;
  border: 1px solid #b6d4fe;
`