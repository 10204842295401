import { useState, useEffect, useContext } from 'react'
import { observer } from 'mobx-react'
import { StoreContext } from '../../store.context'
import styled from '@emotion/styled'
import { 
  MenuComponent,
  PresentationComponent,
  HowItWorksComponent,
  WhyChoosingUsComponent,
  FooterComponent,
  ServiceComponent,
  ContactComponent,
  TestimonialsComponent,
  RegionsComponent,
  ScrollButtonComponent 
} from '../../components/Routes'

function HomePage() {

  const [showScrollUpButton, setShowScrollUpButton] = useState(false)
  const { langStore } = useContext(StoreContext)

  useEffect(() => {

    if(typeof window !== 'undefined'){
      window.onscroll = () => {
        let currentScrollPos = window.pageYOffset
        let minScrollPos = 100
        if(currentScrollPos >= minScrollPos){
          setShowScrollUpButton(true)
        }

        if(currentScrollPos < minScrollPos) {
          setShowScrollUpButton(false)
        }

      }
    }

    const lang = navigator.language

    if(lang.includes('es')){
      langStore.setLanguage('es')
    }else if(lang.includes('BR')){
      langStore.setLanguage('br')
    }else if(lang.includes('en')){
      langStore.setLanguage('en')
    }

  }, [langStore])

  return (
    <>
      <MenuComponent />
      <PresentationComponent />
      <TestimonialsComponent />
      <WhyChoosingUsComponent />
      <ServiceComponent />
      <HowItWorksComponent />
      <RegionsComponent />
      <ContactComponent />
      <ContentBG>
        <FooterComponent />
      </ContentBG>
      {showScrollUpButton && <ScrollButtonComponent /> }
    </>
  )
}

export default observer(HomePage)

const ContentBG = styled('div')(({ theme }) => ({
  minHeight: '20px',
  width: '100%',
  backgroundColor: theme.palette.default[100],
}))