/* eslint-disable no-undef */
import { useState } from 'react'
import {
    Tabs,
    Tab,
    Box,
    Typography
} from '@mui/material'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  }

function WhyChoosingUsComponent() {

    const { t } = useTranslation()

  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Container>
        <WhyWrapper>
            <h2>{t('why.choosing.us.title')}</h2>
            <ItemsWrapper>
                <ImgWrapper>
                    <img src="static/img/img-valores-05.png" alt=""/>
                </ImgWrapper>
                <TabWrapper>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label={t('why.choosing.us.tab.title.1')} sx={{ color: '#fff', fontSize: '1rem' }}/>
                                <Tab label={t('why.choosing.us.tab.title.2')} sx={{ color: '#fff', fontSize: '1rem' }}/>
                                <Tab label={t('why.choosing.us.tab.title.3')} sx={{ color: '#fff', fontSize: '1rem' }}/>
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <TabContent>
                                <p>
                                    {t('why.choose.us.tab.1.text')}
                                </p>
                            </TabContent>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <TabContent>
                                <p>
                                    {t('why.choose.us.tab.2.text')}
                                </p>
                            </TabContent>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <TabContent>
                                <p>
                                    {t('why.choose.us.tab.3.text')}
                                </p>
                                <ul>
                                    <li>
                                        <FontAwesomeIcon icon={faCheck} /> 
                                        <span>{t('why.choose.us.tab3.check.1')}</span>
                                    </li>
                                    <li>
                                        <FontAwesomeIcon icon={faCheck} /> 
                                        <span>{t('why.choose.us.tab3.check.2')}</span>
                                    </li>
                                    <li>
                                        <FontAwesomeIcon icon={faCheck} /> 
                                        <span>{t('why.choose.us.tab3.check.3')}</span>
                                    </li>
                                    <li>
                                        <FontAwesomeIcon icon={faCheck} /> 
                                        <span>{t('why.choose.us.tab3.check.4')}</span>
                                    </li>
                                </ul>
                            </TabContent>
                        </TabPanel>
                    </Box>
                </TabWrapper>
            </ItemsWrapper>
        </WhyWrapper>
    </Container>
  )
}

export default WhyChoosingUsComponent

const Container = styled('div')(({ theme }) => ({
    minHeight: '100px',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: '50px 50px',
    boxSizing: 'border-box'
}))

const WhyWrapper = styled.div`
    min-height: 20px;
    width: 1100px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h2 {
        color: #fff;
        font-size: 2em;
        margin: 0;
        padding: 0;
    }
    @media screen and (max-width: 1371px){ 
        width: 100%;
    }
`

const ItemsWrapper = styled.div`
    min-height: 100px;
    width: 100%;
    margin-top: 40px;
    border-radius: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 40px;
    @media screen and (max-width: 1078px){ 
        align-items: center;
        justify-content: center;
        margin-top: 0;
        gap: 20px;
    }
`

const ImgWrapper = styled.div`
    width: 600px;
    img {
        height: auto;
        width: 100%;
        object-fit: cover;
    }
    @media screen and (max-width: 1192px){ 
        width: 500px;
    }

    @media screen and (max-width: 1078px){ 
        display: none;
    }
`

const TabWrapper = styled.div`
    min-height: 50px;
    width: 600px;
    margin-top: 100px;
    @media screen and (max-width: 1078px){ 
        margin-top: 50px;
    }
`
const TabContent = styled.div`
    min-height: 20px;
    width: 100%;
    p {
        font-size: 1.1rem;
        font-weight: 300;
        color: #fff;
    }
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        margin-top: 20px;
    }

    li {
        display: flex;
        align-items: center;
        color: #fff;
        gap: 20px;
        font-weight: 500;
        font-size: 1.3rem;
        margin-top: 5px;
        &:nth-of-type(1){
            margin-top: 0;
        }
    }
`