// import { Link } from 'react-router-dom'
import { useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import { StoreContext } from '../../store.context'
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from '@mui/material'
import styled from '@emotion/styled'
import FlagAR from '../../assets/img/flag/ar.png'
import FlagBR from '../../assets/img/flag/br.png'
import { useTranslation } from 'react-i18next'
import './footer-component.sass'


function FooterComponent() {

  const { t, i18n } = useTranslation()
  const { langStore } = useContext(StoreContext)

  const handleChangeLang = (e) => {
    langStore.setLanguage(e.target.value)
  }

  useEffect(() => {
    i18n.changeLanguage(langStore.lang)
  })

  return (
    <Footer>
      <FooterTopWrapper>
      <ContactWrapper>
          <ContactInfo>
            <h6>{t('footer.atention.time.title')}</h6>
            <span>{t('footer.atention.time.text.1')}</span>
            <span>{t('footer.atention.time.text.2')}</span>
          </ContactInfo>
        </ContactWrapper>
        <BrandFooterWrapper>
          <img src="/static/icon/brand-logo.svg" alt=""/>
        </BrandFooterWrapper>
        <LanguageWrapper>
            <LanguageSelector>
                <FormControl sx={{ width: '200px' }}>
                  <InputLabel id="language-select-label">{t('footer.select.label')}</InputLabel>
                  <Select
                    labelId="language-select-label"
                    id="language-select"
                    value={langStore.lang}
                    label={t('footer.select.label')}
                    onChange={handleChangeLang}
                    size='small'
                    sx={{
                      color: "#fff",
                      "& .MuiSvgIcon-root": {
                        color: "white",
                      }
                    }}
                  >
                    <MenuItem value="es">{t('footer.select.region.1')}</MenuItem>
                    <MenuItem value="en">{t('footer.select.region.2')}</MenuItem>
                    <MenuItem value="br">{t('footer.select.region.3')}</MenuItem>
                  </Select>
                </FormControl>
            </LanguageSelector>
        </LanguageWrapper>
      </FooterTopWrapper>
      <FooterMiddleWrapper>
        <h6>{t('footer.we.are.title')}</h6>
        <ul>
          <li>
            <span><img src={FlagAR} alt=""/>{t('footer.we.are.region.1')}</span>
          </li>
          <li>
            <span><img src={FlagBR} alt=""/>{t('footer.we.are.region.2')}</span>
          </li>
        </ul>
      </FooterMiddleWrapper>
      <FooterBottomWrapper>
        <span>{t('footer.copyright.title')}</span>
        {/*<LinksActions>
          <ul>
            <li>
              <Link to="/">Términos y condiciones</Link>
            </li>
            <li>
              <Link to="/">Políticas de privacidad</Link>
            </li>
          </ul>
        </LinksActions> */}
      </FooterBottomWrapper>
    </Footer>
  )
}

export default observer(FooterComponent)

const Footer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.default[800],
  minHeight: '100px',
  width: '100%',
  clipPath: `ellipse(72% 100% at 50% 100%)`,
  padding: '50px 50px',
  boxSizing: 'border-box',
}))

const FooterTopWrapper = styled.div`
  min-height: 20px;
  width: 95%;
  margin: auto;
  margin-top: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 40px;
  img {
    height: 60px;
    width: 60px;
    object-fit: cover;
  }
`

const ContactWrapper = styled.div`
  min-height: 20px;
  width: 100%;
  display: flex;
`

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  h6{
    color: #f6f6f6;
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
    font-weight: 600;
  }

  span {
    color: #f6f6f6;
    font-size: 1rem;
    font-weight: 300;
    margin-top: 8px;
    &:nth-of-type(1){
      margin-top: 20px;
    }
  }
`

const LanguageWrapper = styled.div`
  min-height: 20px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
`
const LanguageSelector = styled.div`
  display: flex;
  flex-direction: column;
`

const BrandFooterWrapper = styled.div`
  min-height: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 80px;
    width: 80px;
    object-fit: cover;
  }

  @media screen and (max-width: 758px){
    display: none;
  }

`
const FooterMiddleWrapper = styled.div`
  min-height: 20px;
  width: 95%;
  margin: auto;
  margin-top: 50px;
  display: flex;
  flex-direction: column;

  h6 {
    color: #f6f6f6;
    margin: 0;
    padding: 0;
    font-size: 1.1rem;
    font-weight: 300;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-top: 20px;
  }

  li {
    display: inline-block;
    color: #f6f6f6;
    margin-left: 20px;
    &:nth-of-type(1){
      margin-left: 0;
    }
  }

  span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    img {
      height: 30px;
      width: 30px;
      object-fit: cover;
    }
  }
`

const FooterBottomWrapper = styled.div`
  min-height: 20px;
  width: 95%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #d4d4d4;
  span {
    color: #f6f6f6;
    font-size: .9rem;
    font-weight: 300;
    letter-spacing: -.3px;
  }
`

/*const LinksActions = styled.div`
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    margin-left: 20px;
    &:nth-of-type(1){
      margin-left: 0;
    }
  }

  a {
    text-decoration: none;
    color: #ddd;
    font-size: .9rem;
    font-weight: 500;
    &:hover{
      cursor: pointer;
      color: #fff;
    }
  }
`*/