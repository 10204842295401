import { Link } from 'react-scroll'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'

function ScrollButtonComponent() {
  return (
    <Container>
        <Link to="top" spy={true} smooth={true} offset={-50} duration={1000}><FontAwesomeIcon icon={faAngleUp}/></Link>
    </Container>
  )
}

export default ScrollButtonComponent

const Container = styled.div`
    height: 60px;
    width: 60px;
    position: fixed;
    bottom: 40px;
    right: 20px;
    background: #fff;
    border-radius: 50%;
    font-size: 1.5em;
    color: #1C0C5B;
    box-shadow: 3px 10px 23px -10px rgba(199,199,199,0.6);
    overflow: hidden;
    a {
      height: 100%;
      width: 100%;
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:hover {
        cursor: pointer;
    }
`