// import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { StoreContext } from '../../store.context'
import { observer } from 'mobx-react'
import { Link } from 'react-scroll'
import styled from "@emotion/styled"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { useTranslation } from 'react-i18next'
import './menu-component.sass'

function MenuComponent() {

  const { t } = useTranslation()
  const { langStore } = useContext(StoreContext)

  return (
    <Header>
      <HeaderWrapper>
      <LinksWrapper>
        <LogoContainer>
          <Link to="/">
            <img src="/static/icon/brand-logo.svg" alt=""/>
          </Link>
        </LogoContainer>
        <NavWrapper>
          <ul>
            <li>
              <Link to="services" spy={true} smooth={true} offset={-100} duration={1800} className="link">{t('menu.nav.services')}</Link>
            </li>
            <li>
              <Link to="works" spy={true} smooth={true} offset={-50} duration={1800} className="link">{t('menu.nav.how.it.works')}</Link>
            </li>
            <li>
              <Link to="testimonials" spy={true} smooth={true} offset={-100} duration={1800} className="link">{t('menu.nav.testimonials')}</Link>
            </li>
            <li>
              <Link to="contact" spy={true} smooth={true} offset={-50} duration={1800} className="link">{t('menu.nav.contacto')}</Link>
            </li>
          </ul>
        </NavWrapper>
      </LinksWrapper>
      <ActionsWrapper>
        <SocialWrapper>
          <span>{t('menu.follow.us')}</span>
          <ul>
            <li>
              <a href="https://www.facebook.com/Express-Flex-111718573921616" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebookF}/></a>
            </li>
            {langStore.lang === 'br' ? (
              <li>
                <a href="https://www.instagram.com/expressflexsbr/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram}/></a>
              </li>
            ): (
              <li>
                <a href="https://www.instagram.com/expressflexba/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram}/></a>
              </li>
            )}
            <li>
              <a href="https://www.linkedin.com/in/express-flex-9083021b1/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedinIn}/></a>
            </li>
          </ul>
        </SocialWrapper>
      </ActionsWrapper>
      </HeaderWrapper>
    </Header>
  )
}

export default observer(MenuComponent)

const Header = styled.div`
  min-height: 70px;
  width: 100%;
  position: absolute;
  top: 20px;
  left: 0;
  z-index: 9999;
  padding: 10px 80px;
  box-sizing: border-box;
`
const HeaderWrapper = styled.div`
  width: 1300px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1400px){
    width: 100%;
  }
`

const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;

`

const LogoContainer = styled.div`
  width: 60px;
  img {
    height: auto;
    width: 100%;
    object-fit: cover;
  }
`

const NavWrapper = styled.div`
  ul{
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      margin-left: 20px;
      &:nth-of-type(1){
        margin-left: 0;
      }

      a{
        text-decoration: none;
        color: #fff;
        text-transform: capitalize;
      }

    }
  }

  @media screen and (max-width: 990px){
    display: none;
  }
`

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`

const SocialWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  span {
    color: #fff;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
      display: inline-block;
      margin-left: 10px;
      &:first-of-type{
        margin-left: 0;
      }  
  }

  a {
    height: 30px;
    width: 30px;
    display: inline-block;
    background: #fff;
    border-radius: 50%;
    color: #1C0C5B;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(1);
    transition: transform ease .3s;
    &:hover{
      cursor: pointer;
      transform: scale(1.2);
    }
  }
`