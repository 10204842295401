import styled from "@emotion/styled"
import { useTranslation } from 'react-i18next'

function HowItWorksComponent() {

  const { t } = useTranslation()

  return (
    <Container id="works">
        <ContentWrapper>
            <IconsWrapper>
              <ul>
                <li>
                  <IconWrapper>
                    <img src="static/img/icons-services-07.svg" alt=""/>
                    <span>{t('how.it.works.title.1')}</span>
                  </IconWrapper>
                </li>
                <li>
                  <IconWrapper>
                    <img src="static/img/icons-services-06-06.svg" alt=""/>
                    <span>{t('how.it.works.title.2')}</span>
                  </IconWrapper>
                </li>
                <li>
                  <IconWrapper>
                    <img src="static/img/icons-services-08.svg" alt=""/>
                    <span>{t('how.it.works.title.3')}</span>
                  </IconWrapper>
                </li>
                <li>
                  <IconWrapper>
                    <img src="static/img/icons-services-09.svg" alt=""/>
                    <span>{t('how.it.works.title.4')}</span>
                  </IconWrapper>
                </li>
              </ul>
            </IconsWrapper>
            <ImageWrapper>
                <img src="static/img/img-app-05.png" alt=""/>
            </ImageWrapper>
            <DescriptionWrapper>
              <p>
                {t('how.i.works.description')}
              </p>
            </DescriptionWrapper>
        </ContentWrapper>
    </Container>
  )
}

export default HowItWorksComponent

const Container = styled('div')(({ theme }) => ({
    minHeight: '200px',
    width: '100%',
    backgroundColor: theme.palette.background.default,
    padding: '50px 50px',
    boxSizing: 'border-box'
}))

const ContentWrapper = styled.div`
  min-height: 100px;
  width: 1100px;
  margin: auto;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  @media screen and (max-width: 1197px){
    width: 100%;
  }
  @media screen and (max-width: 713px){
    flex-direction: column-reverse;
  }
`

const IconsWrapper = styled.div`
  min-height: 20px;
  ul{
    list-style-type: none;
    width: 100%;
    display: inline-block;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  li {
    margin-top: 20px;
    @media screen and (max-width: 713px){
      display: inline-block;
    }
  }
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  img {
    height: 70px;
    width: 70px;
    object-fit: cover;
  }

  span {
    width: 150px;
    font-weight: 500;
    color: #fff;
    @media screen and (max-width: 713px){
      text-align: center;
    }
  }

  @media screen and (max-width: 713px){
    flex-direction: column;
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: auto;
    width: 600px;
    display: inline-block;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    object-fit: cover;
    @media screen and (max-width: 1197px){
      width: 500px;
    }
    @media screen and (max-width: 1079px){
      width: 400px;
    }
  }
`

const DescriptionWrapper = styled.div`
  width: 500px;
  p {
    font-size: 1.2rem;
    font-weight: 300;
    color: #fff;
  }
  @media screen and (max-width: 1014px){
      display: none;
  }
`